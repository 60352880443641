export const ContentCollection = Object.freeze({
  WIRELESS: 1,
  BROADBAND: 2,
  VIDEO: 3,
  GENERAL: 4,
  IT: 5,
  PARTNER_CONTENT: 6,
  RESEARCH: 7,
  FTTH: 10,
  MWC2025: 148
})
